<template>
  <div>
    <vue-html2pdf
      ref="html2Pdf"
      class="my-export"
      :show-layout="false"
      :preview-modal="true"
      :enable-download="false"
      :manual-pagination="false"
      :paginate-elements-by-height="1400"
      filename="doc"
      pdf-format="a4"
      pdf-content-width="auto"
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="assignLodingTime($event)"
      @hasDownloaded="hasDownloaded"
    >
      <section
        id="my-pdf"
        ref="pdfContent"
        slot="pdf-content"
        :style="pdfContentStyle"
      ></section>
    </vue-html2pdf>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import { mapActions } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import Loading from "@/components/Loading";

export default {
  name: "pdfExport",
  components: {
    VueHtml2pdf,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      isUpload: false,
      patientId: null,
      remark: "",

      // Enhanced PDF options
      htmlToPdfOptions: {
        pagebreak: {
          mode: [],
          before: ".page-break-before",
          avoid: ".avoid-break",
        },
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        margin: [15, 5, 15, 5], // Adjusted margins for better layout
        html2canvas: {
          dpi: 300,
          scale: 4,
          letterRendering: true,
          useCORS: true,
          logging: true, // Helpful for debugging
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          compress: true,
        },
      },
      loadingTime: 0,
      pdfContentStyle: {
        letterSpacing: "0.01rem",
        fontSize: "13px",
        margin: "0.5rem 3rem",
        lineHeight: "1.5",
      },
    };
  },

  methods: {
    ...mapActions({
      createMediaFiles: "moduleMediaFile/createMediaFiles",
    }),

    async generatePdf({ header, body, footer, option }) {
      try {
        this.isLoading = true;
        this.isUpload = option?.isUpload || false;
        this.patientId = option?.patientId || null;
        this.remarkTitle = option?.remarkTitle || "";
        this.htmlToPdfOptions.jsPDF.orientation =
          option?.orientation || "portrait";

        // Apply custom styles for better page breaks
        if (option?.fontSize) {
          this.pdfContentStyle.fontSize = option.fontSize;
        }

        const pdfContent = this.$refs.pdfContent;
        pdfContent.innerHTML = ""; // Clear previous content

        // Add page break classes
        if (header) {
          const headerElement = header.cloneNode(true);
          headerElement.classList.add("avoid-break");
          await pdfContent.appendChild(headerElement);
        }

        if (body) {
          const bodyElement = body.cloneNode(true);
          // Add page break classes to sections if needed
          const sections = bodyElement.querySelectorAll(".section");
          sections.forEach(section => {
            section.classList.add("avoid-break");
          });
          await pdfContent.appendChild(bodyElement);
        }

        if (footer) {
          const footerElement = footer.cloneNode(true);
          footerElement.classList.add("page-break-before");
          await pdfContent.appendChild(footerElement);
        }

        await this.$nextTick();
        const data = await this.$refs.html2Pdf.generatePdf();
        return data;
      } catch (error) {
        console.error("PDF generation error:", error);
        this.isLoading = false;
        throw error;
      }
    },

    assignLodingTime(e) {
      this.loadingTime = e;
    },
    async hasDownloaded(pdfBlob) {
      this.resetLodingTime();
      if (!this.isUpload) return;
      // await this.handleCreateMediaFile(pdfBlob);
      // eventBus.$emit("pollForUpdates", [pdfBlob]);
      // Create URL from blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const filename = `${
        this.remarkTitle
      }${this.$utils.generateDateTimeString()}_Signed`;

      // Open SignDoc in new window with the PDF URL and parameters
      const params = new URLSearchParams({
        src: pdfUrl,
        id: this.patientId,
        patientId: this.patientId,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        filename: filename,
      }).toString();

      window.open(`/document?${params}`, "_blank");
      this.$emit("openSignTabCompleted");
    },
    resetLodingTime() {
      this.isLoading = false;
      setTimeout(() => {
        this.loadingTime = 0;
      }, 1000);
    },
    async handleCreateMediaFile(pdfBlob) {
      await this.createMediaFiles({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        data: {
          category: "Document",
          patientId: this.patientId ? String(this.patientId) : null,
          remark: `${
            this.remarkTitle
          }${this.$utils.generateDateTimeString()}_Signed`,
          filesMetadata: [{ id: null, contentType: "application/pdf" }],
        },
        files: [pdfBlob],
      });
    },
  },
  mounted() {
    // Apply styles to container
    const containerEl = document.querySelector(
      ".vue-html2pdf.my-export .layout-container .content-wrapper"
    );
    if (containerEl) {
      containerEl.style.setProperty("margin", "0", "important");
      containerEl.style.setProperty("background-color", "white", "important");
    }

    // Add global styles for PDF generation
    const styleEl = document.createElement("style");
    styleEl.textContent = `
      .avoid-break {
        page-break-inside: avoid !important;
        -webkit-page-break-inside: avoid !important;
      }
      .page-break-before {
        page-break-before: always !important;
        -webkit-page-break-before: always !important;
      }
      #my-pdf {
        font-family: 'Sarabun', sans-serif;
      }
      #my-pdf section {
        margin-bottom: 20px;
      }
    `;
    document.head.appendChild(styleEl);
  },

  beforeDestroy() {
    eventBus.$off("generatePDF");
  },
};
</script>

<style>
#my-pdf {
  font-size: 13px;
  margin: 0.5rem 3rem;
}

/* Additional styles for better page breaks */
.avoid-break {
  page-break-inside: avoid;
  -webkit-page-break-inside: avoid;
}

.page-break-before {
  page-break-before: always;
  -webkit-page-break-before: always;
}
</style>
